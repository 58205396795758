/////// IMAGES FOR HOMEPAGE //////////
.women-credit-card {
  // border: 1px solid red;
  background-image: url("../../images/woman-credit-card.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 37.5vh;
  left: 27.5vw;
  width: 15%;
  height: 25%;
}

.man-with-mask {
  // border: 1px solid red;
  background-image: url("../../images/man-with-mask.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 37.5vh;
  left: 77.5vw;
  width: 15%;
  height: 25%;
}

/////// IMAGES FOR SIGN UP //////////
.man-with-cup-and-suitcase {
  // border: 1px solid red;
  background-image: url("../../images/man-with-cup-and-suitcase.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 65vh;
  left: 10vw;
  width: 10%;
  height: 35%;
}

.man-with-big-pencil {
  // border: 1px solid red;
  background-image: url("../../images/man-with-big-pencil.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 11vh;
  right: 25vw;
  width: 5%;
  height: 20%;
}

.little-tree {
  // border: 1px solid red;
  background-image: url("../../images/little-tree.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 25vh;
  left: 45vw;
  width: 5%;
  height: 7.5%;
}

/////// IMAGES FOR SIGN IN //////////
.man-rising-cup {
  // border: 1px solid red;
  background-image: url("../../images/man-rising-cup.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 65vh;
  left: 10vw;
  width: 10%;
  height: 35%;
}

.two-men-climbing {
  // border: 1px solid red;
  background-image: url("../../images/two-men-climbing.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 21.5vh;
  right: 7.5vw;
  width: 10%;
  height: 35%;
}

/////// IMAGES FOR USER INFORMATIONS //////////
.woman-phoning {
  // border: 1px solid red;
  background-image: url("../../images/woman-phoning.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 52.5vh;
  left: 70vw;
  width: 15%;
  height: 30%;
  z-index: -1;
}

.woman-with-documents {
  // border: 1px solid red;
  background-image: url("../../images/woman-with-documents.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 12.5vh;
  left: 37.5vw;
  width: 15%;
  height: 20%;
}

/////// IMAGES FOR NEW CAMPAIGN //////////
.man-reading {
  // border: 1px solid red;
  background-image: url("../../images/man-reading.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 12.5vh;
  left: 65vw;
  width: 15%;
  height: 20%;
}

.man-rising-hand {
  // border: 1px solid red;
  background-image: url("../../images/man-rising-hand.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 52.5vh;
  left: 20vw;
  width: 10%;
  height: 30%;
}

/////// IMAGES FOR YOUR CAMPAIGNS //////////
.man-bubble-idea {
  // border: 1px solid red;
  background-image: url("../../images/man-bubble-idea.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 65vh;
  left: 40vw;
  width: 10%;
  height: 35%;
  z-index: -1;
}

.woman-sitting-with-computer {
  // border: 1px solid red;
  background-image: url("../../images/women-sitting-with-computer.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 16vh;
  right: 8vw;
  width: 15%;
  height: 20%;
}

/////// IMAGES FOR SYSTEM TEST //////////
.woman-sitting-on-desk {
  // border: 1px solid red;
  background-image: url("../../images/woman-sitting-on-desk.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 16vh;
  left: 32.5vw;
  width: 15%;
  height: 20%;
}

.man-touching-screen {
  // border: 1px solid red;
  background-image: url("../../images/man-touching-screen.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 65vh;
  right: 7.5vw;
  width: 10%;
  height: 35%;
  z-index: -1;
}

///// Media queries //////
@media (max-width: 1200px) {
  .women-credit-card {
    top: 37.5vh;
    left: 9vw;
    width: 15%;
    height: 25%;
  }

  .man-with-mask {
    top: 37.5vh;
    left: 77.5vw;
    width: 15%;
    height: 25%;
  }

  .man-with-cup-and-suitcase {
    top: 62.5vh;
  }

  .man-with-big-pencil {
    top: 32.5vh;
    right: 35vw;
    width: 5%;
    height: 20%;
  }

  .little-tree {
    top: 50vh;
    left: 35vw;
  }

  .man-rising-cup {
    top: 62.5vh;
  }

  .two-men-climbing {
    top: 45vh;
    right: 15vw;
  }

  .woman-phoning {
    top: 65vh;
    left: 65vw;
  }

  .woman-with-documents {
    top: 30vh;
    left: 25vw;
  }

  .man-reading {
    top: 30vh;
    left: 57.5vw;
  }

  .man-rising-hand {
    top: 70vh;
    left: 2vw;
  }

  .man-bubble-idea {
    top: 40vh;
    left: 15vw;
    height: 30%;
    z-index: +1;
  }

  .woman-sitting-with-computer {
    top: 40vh;
  }

  .woman-sitting-on-desk {
    top: 35vh;
    left: 17.5vw;
  }

  .man-touching-screen {
    top: 65vh;
    right: 9vw;
  }
}

@media (max-width: 1000px) {
  .man-with-cup-and-suitcase {
    // border: 1px solid red;
    top: 32.5vh;
    left: 22.5vw;
    width: 20%;
    height: 20%;
  }

  .man-with-big-pencil {
    // border: 1px solid red;
    top: 35vh;
    right: 27.5vw;
    width: 10%;
    height: 15%;
  }

  .little-tree {
    top: 70vh;
    left: 47.5vw;
  }

  .woman-phoning {
    top: 40vh;
    left: 62.5vw;
    width: 20%;
    height: 20%;
  }

  .woman-with-documents {
    top: 40vh;
    left: 20vw;
  }

  .man-bubble-idea {
    top: 40vh;
    left: 15vw;
    height: 25%;
    z-index: +1;
  }

  .woman-sitting-with-computer {
    top: 40vh;
  }

  .woman-sitting-on-desk {
    top: 40vh;
    left: 10vw;
  }

  .man-touching-screen {
    top: 32.5vh;
    right: 12.5vw;
  }
}

@media (max-width: 950px) {
  .man-reading {
    top: 42.5vh;
    left: 65vw;
  }

  .man-rising-hand {
    top: 42.5vh;
    left: 15vw;
    width: 20%;
    height: 20%;
  }
}

@media (max-width: 800px) {
  .man-with-big-pencil {
    // border: 1px solid red;
    top: 35vh;
    right: 25vw;
    width: 10%;
    height: 15%;
  }

  .woman-phoning {
    top: 45vh;
    left: 82.5vw;
    width: 17%;
    height: 17%;
  }

  .woman-with-documents {
    top: 43vh;
    left: 1vw;
  }
}

@media (max-width: 700px) {
  .women-credit-card {
    // border: 1px solid red;
    top: 70vh;
    left: 10vw;
    width: 20%;
    // height: 10%;
  }

  .man-with-mask {
    // border: 1px solid red;
    top: 70vh;
    left: 70vw;
    width: 20%;
    // height: 10%;
  }

  .man-reading {
    top: 45vh;
    left: 85vw;
    width: 15%;
    height: 15%;
    z-index: -1;
  }

  .man-rising-hand {
    top: 45vh;
    left: 0.5vw;
    width: 15%;
    height: 15%;
    z-index: -1;
  }
}

@media (max-width: 650px) {
  .man-with-cup-and-suitcase {
    // border: 1px solid red;
    top: 32.5vh;
    left: 1vw;
    width: 20%;
    height: 20%;
  }

  .man-with-big-pencil {
    // border: 1px solid red;
    top: 35vh;
    right: 1vw;
    width: 20%;
    height: 15%;
  }

  .little-tree {
    top: 70vh;
    left: 47.5vw;
  }

  .man-rising-cup {
    top: 25vh;
    left: 1vw;
    width: 20%;
    height: 20%;
  }

  .two-men-climbing {
    top: 25vh;
    right: 1vw;
    width: 20%;
    height: 20%;
  }

  .man-bubble-idea {
    top: 47.5vh;
    left: 1vw;
    height: 25%;
  }

  .woman-sitting-with-computer {
    top: 47.5vh;
    right: 1vw;
    width: 25%;
    height: 25%;
  }

  .woman-sitting-on-desk {
    top: 47.5vh;
    left: 1vw;
    width: 15%;
    height: 15%;
  }

  .man-touching-screen {
    top: 37.5vh;
    right: 1vw;
  }
}

@media (max-width: 500px) {
  .women-credit-card {
    // border: 1px solid red;
    top: 80vh;
    height: 20%;
  }

  .man-with-mask {
    // border: 1px solid red;
    top: 80vh;
    height: 20%;
  }
}

@media (max-width: 400px){
  .woman-sitting-on-desk {
    top: 54vh;
    left: 1vw;
    width: 15%;
    height: 15%;
  }

  .man-touching-screen {
    top: 54vh;
    width: 15%;
    height: 15%;
    z-index: +1;
  }
}
