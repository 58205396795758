/////////// SIGNIN STYLES /////////

.signin-container {
  // border: 1px solid red;
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
}

.signin-form {
  // border: 1px solid orange;
  display: grid;
  grid-template-rows: auto auto auto;
  width: 40%;
  margin-top: 10vh;
}

.authentication-failure {
  background-color: $dark-blue1-full-opacity;
  color: $dark-blue1-full-opacity;
  width: fit-content;
  text-align: center;
  padding: 0.5vh;
  border-radius: 0.5vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.authentication-failure-show {
  background-color: $red-full-opacity;
  color: $white-full-opacity;
  width: fit-content;
  text-align: center;
  padding: 0.5vh;
  border-radius: 0.5vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

/////////// SIGNIN STYLES END /////////

/////////// SIGNUP STYLES /////////

.signup-container {
  // border: 1px solid red;
  display: grid;
  grid-template-rows: repeat(7, auto);
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
}

.signup-form {
  // border: 1px solid orange;
  display: grid;
  grid-template-rows: auto auto auto;
  width: 40%;
  margin-top: 10vh;
}

.password-instructions {
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
  width: fit-content;
  font-size: 0.75em;
  text-align: center;
  padding: 0.5vh;
  border-radius: 0.5vh;
  cursor: pointer;
}

.cgu-validation-container {
  // border: 1px solid purple;
  display: grid;
  grid-template-columns: max-content max-content;
  width: 100%;
  margin-top: 3vh;
}

.captcha-container {
  // border: 1px solid salmon;
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  margin-top: 3vh;
  font-size: 0.75em;
}

li {
  color: $dark-blue1-full-opacity;
  text-align: left;
  font-style: italic;
}

/////////// SIGNUP STYLES END /////////

////////// INPUTS STYLE ///////////

.input-container-signinup {
  // border: 1px solid purple;
  border-bottom: 1px solid rgba(255, 255, 255, 0.455);
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  margin-left: auto;
  margin-right: auto;
}

.input-signinup {
  // border: 1px solid red;
  background-color: $dark-blue1-full-opacity;
  color: $white-full-opacity;
  width: 100%;
  justify-self: start;
}

.input-signinup:focus {
  outline: none;
}

///////// INPUTS STYLE END ///////////

// Media queries
@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 1000px) {
}

@media (max-width: 956px) {
  .signup-form {
    width: 65%;
  }
}

@media (max-width: 515px) {
  .cgu-validation-container {
    // border: 1px solid purple;
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }
}
