/// NAVBAR STYLES ////

.navbar-container {
  // border: 3px solid blue;
  justify-content: center;
}

/////// CATCHPHRASE ////////
.catchphrase-element {
  // border: 3px solid green;
  display: grid;
  grid-template-rows: max-content max-content;
  // margin-top: 25vh;
  justify-items: center;
  color: $white-full-opacity;
  text-align: center;
}

.catchphrase-container {
  // border: 1px solid purple;
  // display: grid;
  // grid-template-rows: repeat(2, auto);
  margin-top: 25vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.catchphrase {
  // border: 3px solid red;
  font-size: 2em;
  font-family: Nunito-VariableFont, sans-serif;
}

.cta-container {
  // border: 1px solid yellow;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.cta-button {
  width: fit-content;
  background-color: $light-blue3-full-opacity;
  padding: 5px;
  border: 2px solid $white-full-opacity;
  border-radius: 5px;
  box-shadow: 0px 3px 5px black;
  color: $white-full-opacity;
  font-weight: bold;
  cursor: pointer;
  font-family: "Plus Jakarta Sans Variable";
  height: fit-content;
  margin-top: 2vh;
}

// .cta-button-selected {
//   width: fit-content;
//   background-color: $light-blue3-full-opacity;
//   padding: 5px;
//   border: 2px solid $white-full-opacity;
//   border-radius: 5px;
//   box-shadow: 0px 3px 5px black;
//   color: $white-full-opacity;
//   font-weight: bold;
//   cursor: pointer;
//   font-family: "Plus Jakarta Sans Variable";
//   height: fit-content;
//   margin-top: 2vh;
// }

/// CATCHPHRASE END

/// MENU STYLES ////
.menu-container {
  // border: 3px solid orange;
  display: grid;
  grid-template-rows: repeat(4, max-content);
  place-items: center;
  // align-items: start;
  padding-top: 5vh;
}

.menu-button {
  // border: 1px solid red;
  background-color: $light-blue1-half-opacity;
  display: grid;
  grid-template-columns: 1fr 5fr;
  place-items: center;
  width: 90%;
  height: 3.75vh;
  border-radius: 5px;
  margin-bottom: 5vh;
  padding: 1vh 0.5vh 1vh 0.5vh;
  // padding-top: 1vh;
  // padding-bottom: 1vh;
  cursor: pointer;
  // border: 5px solid rgb(0, 0, 0, 0);
  color: $white-full-opacity;
  font-family: "Plus Jakarta Sans Variable";

  box-shadow: 3px 5px 1px 2.5px rgb(10, 10, 16);
}

.menu-button-selected {
  background-color: $dark-blue2-full-opacity;
  display: grid;
  grid-template-columns: 1fr 5fr;
  place-items: center;
  width: 90%;
  height: 3.75vh;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 5vh;
  padding: 1vh 0.5vh 1vh 0.5vh;
  cursor: pointer;
  border: 1px solid $white-full-opacity;
  color: $white-full-opacity;
  font-family: "Plus Jakarta Sans Variable";

  box-shadow: 0px 0px 5px 0px rgb(255, 255, 255, 1);
  font-weight: bold;
  transition: width 0.3s, height 0.3s, background-color 0.3s;
}

.menu-button-icon {
  // border: 1px solid red;
}

.menu-button-text {
  // border: 1px solid red;
  font-family: Designosaur, sans-serif;
}

.system-test-button-container {
  background-color: $white-full-opacity;
  display: grid;
  grid-template-columns: 1fr 5fr;
  place-items: center;
  width: 90%;
  height: fit-content;
  border-radius: 5px;
  // padding-top: 0.33vh;
  // padding-bottom: 0.33vh;
  margin-bottom: 5vh;
  cursor: pointer;
  border: 5px solid rgb(43, 44, 64, 0);
  box-shadow: 3px 5px 1px 2.5px rgb(10, 10, 16);
  color: $light-blue1-full-opacity;
  font-family: "Plus Jakarta Sans Variable";
}

/// MENU STYLES END////

/// NAVBAR STYLES END ////

// Media queries
@media (max-width: 1200px) {
  .navbar-container {
    height: fit-content;
  }

  .catchphrase-element {
    grid-template-columns: auto auto;
    margin-top: 0;
    place-items: center;
    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
    padding-bottom: 1vh;
  }

  .catchphrase-container {
    margin-top: 2.5vh;
  }

  .menu-container {
    grid-template-columns: repeat(4, 25%);
  }
}

@media (max-width: 1000px) {
  .menu-container {
    grid-template-columns: repeat(2, 50%);
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .catchphrase {
    font-size: 1.5em;
  }

  .cta-container {
    padding-right: 2.5vw;
  }

  .cta-button {
    font-size: 0.75em;
  }

  .menu-container {
    grid-template-columns: repeat(1, 100%);
    width: 75%;
    padding-top: 1vh;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-button {
    margin-bottom: 1vh;
  }

  .menu-button-selected {
    margin-bottom: 1vh;
  }
}

@media (max-width: 400px){
  .catchphrase {
    padding: 3px;
    font-size: 1.1em;
  }
}
