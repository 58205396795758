@import "./variables";

@import "./globalStyles/animations";
@import "./globalStyles/buttons&badges";
@import "./globalStyles/containers";
@import "./globalStyles/cursors";
@import "./globalStyles/resetStyles";
@import "./globalStyles/texts";

@import "./pagesStyles/header";

@import "./pagesStyles/main";
@import "./pagesStyles/navbar";
@import "./pagesStyles/outlet";

@import "./pagesStyles/footer";
@import "./pagesStyles/homepageV2";
@import "./pagesStyles/signinupV2";
@import "./pagesStyles/resetPasswordV2";
@import "./pagesStyles/userInfosV2";
@import "./pagesStyles/campaignSetUpV2";
@import "./pagesStyles/campaignSetUpControlV2";
@import "./pagesStyles/yourCampaignsV2";
@import "./pagesStyles/cguV2";
@import "./pagesStyles/cgvV2";
@import "./pagesStyles/cookies";
@import "./pagesStyles/page404V2";
@import "./pagesStyles/payementV2";
@import "./pagesStyles/systemTest";
@import "./globalStyles/images";

body {
  background-color: $dark-blue1-full-opacity;
  font-family: Plus Jakarta Sans Variable, sans-serif;
}

.main-view-title {
  // border: 1px solid orange;
  display: grid;
  grid-template-columns: max-content auto;
  place-items: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: 10vh;
  border-bottom: 3px solid $white-full-opacity;
  padding: 0;
}

.main-view-title-icon {
  // border: 1px solid red;
  width: 100%;
  font-size: 2em;
  color: white;
  padding-top: 0.75vh;
}

.main-view-title-text {
  // border: 1px solid brown;
  width: 100%;
  padding-left: 1vw;
}

.announcement-container {
  // width: 100%;
  height: fit-content;
  background-color: $violet-full-opacity;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 3px 5px 1px 2.5px $black-full-opacity;
  text-align: center;
  padding: 1vh;
  margin-bottom: 3vh;
}

.display-none {
  display: none;
}

.faded {
  opacity: 0.25;
  transition: opacity 0.5s;
}

// Media queries
@media (max-width: 1200px) {
  .main-view-title {
    margin-top: 10vh;
  }
}
