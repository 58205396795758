.homepage-text-container {
  // border: 3px solid orange;
  width: 50%;
  color: $white-full-opacity;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
  text-align: center;
}

.act-link {
  // border: 1px solid blue;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  // justify-items: center;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.act-name {
  // border: 1px solid red;

  padding-bottom: 1vh;
}

.act-logo {
  // border: 1px solid green;
  padding-left: 0.5vw;
}

// Media queries
@media (max-width: 1200px) {
  .homepage-text-container {
    margin-top: 10vh;
  }
}
