//////////// MAIN ////////////////////

.main-container {
  // border: 3px solid red;
  display: grid;
  grid-template-columns: 20vw auto;
  width: 99.99%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 90vh;
  margin-top: $header-height;
  // padding-bottom: $header-height;
}

///////// MAIN END /////////////

// Media queries
@media (max-width: 1200px) {
  .main-container {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    align-content: start;
  }
}
