.reset-password-modal {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.send-reset-instructions-form {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  border-radius: 1vh;
  width: 50%;
  margin-top: 40vh;
  margin-left: auto;
  margin-right: auto;
  padding: 5vh;
  text-align: center;
}

.reset-password-form {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-items: center;
  width: 40%;
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5vh;
  border-radius: 1vh;
}

.input-container {
  background-color: red;
}

.input {
  // border: 3px solid red;
  background-color: $dark-blue1-full-opacity;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.455);
  color: $white-full-opacity;
  width: 100%;
}

.input:focus {
  outline: none;
}

///// MEDIA QUERIES /////////
@media (max-width: 700px) {
  .send-reset-instructions-form {
    width: 85%;
  }

  .reset-password-form {
    width: 60%;
  }
}

@media (max-width: 400px) {
  .reset-password-form {
    width: 80%;
  }
}
