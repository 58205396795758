//////////// HEADER //////////////
.header-container {
  // border: 3px solid orange;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  display: grid;
  grid-template-columns: 20vw auto;
  justify-items: center;
  align-items: center;
  background-color: $dark-blue3-full-opacity;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.25);
  z-index: 998;
}

// Container avec les logos
.title-container {
  // border: 3px solid purple;
  width: 100%;
  // height: fit-content;
  display: grid;
  grid-template-columns: 90% 10%;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  font-family: "Nunito-VariableFont";
}

.logo-container {
  // border: 3px solid blue;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5em;
  padding-left: 1vw;
}

.social-container {
  // border: 3px solid red;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-self: end;
  // justify-items: center;
  // align-items: center;
  color: $white-full-opacity;
  // background-color: $light-blue1-full-opacity;
  padding-top: 1vh;
}

.signinup-container {
  // border: 1px solid yellow;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-items: end;
}

.signinup-button {
  padding-right: 2vw;
  cursor: pointer;
}

//////////// HEADER END //////////////

// Media queries
@media (max-width: 1200px) {
  .header-container {
    grid-template-columns: 25vw auto;
  }
}

@media (max-width: 1000px) {
  .header-container {
    grid-template-columns: 30vw auto;
  }
}

@media (max-width: 800px) {
  .header-container {
    grid-template-columns: 35vw auto;
  }
}

@media (max-width: 650px) {
  .header-container {
    grid-template-columns: 40vw auto;
  }
}

@media (max-width: 590px) {
  .header-container {
    grid-template-columns: 45vw auto;
  }
}

@media (max-width: 500px) {
  .header-container {
    grid-template-columns: 65vw auto;
  }
}
