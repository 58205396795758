/////////// USER INFOS STYLES /////////

.user-infos-container {
  // border: 1px solid red;
  display: grid;
  grid-template-rows: repeat(6, 5vh);
  align-items: center;
}

.info-container {
  // border: 1px solid blue;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 5vh;
}

.info-title {
  // border: 1px solid purple;
  font-weight: bold;
  width: 100%;
  text-align: right;
}

.info-content {
  // border: 1px solid orange;

  justify-self: start;
  width: fit-content;
}

/////////// USER INFOS STYLES END /////////

/////////// USER INFOS EDITION STYLES /////////
.user-infos-form {
  // border: 1px solid purple;
  display: grid;
  grid-template-rows: repeat(8, 5vh);
  align-items: center;
  padding-bottom: 5vh;
}

.input {
  // border: 3px solid red;
  background-color: $dark-blue1-full-opacity;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.455);
  color: $white-full-opacity;
  width: 100%;
}

.input:focus {
  outline: none;
}

.submit-container {
  // border: 1px solid orange;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
}

.input-checking-container{
  // border: 1px solid red;
  margin-top: 10vh;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

////////// USER INFOS EDITION STYLES END ////////

// Media queries
@media (max-width: 500px) {
  .info-title {
    font-size: 0.8em;
  }

  .info-content {
    font-size: 0.8em;
  }
}

@media (max-width: 360px) {
  .info-title {
    font-size: 0.65em;
  }

  .info-content {
    font-size: 0.65em;
  }
}
