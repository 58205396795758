#payement-form-container {
  // border: 1px solid red;
  display: grid;
  grid-template-rows: 1fr;
  // min-width: 90%;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  padding: 2.5vh;
}

#payement-disclaimer {
  // border: 1px solid yellow;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#payement-form {
  // border: 1px solid orange;
  margin-left: auto;
  margin-right: auto;
}

//Media queries
@media (max-width: 380px) {
}
