#data-collect-container {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: 1fr;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.item-setup-container {
  // border: 3px solid yellow;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 3vh;
}

.setup-title {
  // border: 3px solid red;
  display: grid;
  grid-template-columns: 95% 5%;
  // align-content: center;
  width: 100%;
  height: fit-content;
  text-align: left;
  background-color: $green-full-opacity;
  padding-top: 0.5vh;
  // padding-left: 1vh;
  // margin-bottom: 2.5vh;
  box-shadow: 0px 5px 5px 0px $black-full-opacity;
  z-index: 1;
}

.title-icon {
  // border: 3px solid blue;
  padding-left: 0.5vh;
}

.setup-step{
  border: 1px solid $white-full-opacity;
  border-radius: 50%;
  background-color: $white-full-opacity;
  color: $dark-blue1-full-opacity;
}

.title-part {
  // border: 3px solid purple;
  display: grid;
  grid-template-columns: 5% 95%;
  // justify-items: start;
}

.title-text {
  // border: 3px solid salmon;
  font-family: Designosaur, sans-serif;
  // font-weight: bold;
  padding-left: 0.1vw;
}

.arrow-part {
  // border: 3px solid black;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

////////// STYLES FOR TEMPLATE SETUP ////////

#template-setup-processing {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $light-blue1-half-opacity;
  border-radius: 0 0 1vh 1vh;
  background-color: $dark-blue2-full-opacity;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  padding: 1vh;
  margin-bottom: 2.5vh;
}

#template-radio-buttons {
  // border: 1px solid orange;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: start;
  height: fit-content;
}

#template-screenshot-container {
  // border: 1px solid pink;
  // height: 40vh;
  // overflow: hidden;
}

.template-screenshot {
  // border: 1px solid rgb(211, 211, 211);
  border-radius: 1vh;
}

////////// STYLES FOR TEMPLATE SETUP END ////////

////////// STYLES FOR GROUP SETUP ////////

#group-setup-processing {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $light-blue1-half-opacity;
  border-radius: 0 0 1vh 1vh;
  background-color: $dark-blue2-full-opacity;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  margin-bottom: 2.5vh;
  padding-bottom: 2vh;
}

.group-setup {
  // border: 1px solid rgb(211, 211, 211);
  width: 90%;
  font-size: 0.9em;
  padding-top: 2vh;
  text-align: left;
  font-family: Designosaur, sans-serif;
}

////////// STYLES FOR GROUP END ////////

////////// STYLES FOR SCHEDULE SETUP ////////

#schedule-setup-processing {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $light-blue1-half-opacity;
  border-radius: 0 0 1vh 1vh;
  background-color: $dark-blue2-full-opacity;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  margin-bottom: 2.5vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.schedule-setup {
  // border: 1px solid red;
  width: 100%;
  font-family: Designosaur, sans-serif;
}

// #campaign-scheduling {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
// }

////////// STYLES FOR SCHEDULE END ////////

////////// STYLES FOR ACCEPTANCE SETUP ////////

#acceptance-setup-processing {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $light-blue1-half-opacity;
  border-radius: 0 0 1vh 1vh;
  background-color: $dark-blue2-full-opacity;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-bottom: 2.5vh;
  // padding-top: 2vh;
  // padding-bottom: 2vh;
}

.acceptance-setup {
  // border: 1px solid rgb(211, 211, 211);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 3vh;
}

////////// STYLES FOR ACCEPTANCE END ////////

////////// STYLES FOR MISSING INPUTS ////////

.missing-inputs-modal {
  // border: 1px solid $light-blue1-full-opacity;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(43, 44, 64);
  position: fixed;
  top: 75vh;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding-left: 1vh;
  padding-top: 1vh;
  border-radius: 1vh 0 0 1vh;
  z-index: 999;
}

.missing-inputs-container {
  // margin-top: 70vh;
  // margin-left: 62.5vw;
}

////////// STYLES FOR MISSING INPUTS END ////////

//Media queries
@media (max-width: 1000px) {
  .title-text {
    padding-left: 0.25vw;
  }
}

@media (max-width: 950px) {
  #data-collect-container {
    width: 95%;
  }
}

@media (max-width: 850px) {
  .arrow-part {
    padding-right: 10vw;
  }
}

@media (max-width: 800px) {
  .setup-title {
    grid-template-columns: 90% 10%;
  }

  .title-text {
    padding-left: 2vw;
    font-size: 0.9em;
  }
}

@media (max-width: 710px) {
  #template-setup-processing {
    grid-template-columns: none;
    grid-template-rows: max-content max-content;
    align-items: start;
    // font-size: 0.8em;
  }

  #template-screenshot-container {
    margin-top: 1vh;
  }

  #group-setup-processing {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }

  #schedule-setup-processing {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr 1fr;
    padding-left: 2vw;
    text-align: start;
    // justify-items: start;
  }

  .schedule-setup {
    margin-bottom: 2vh;
    justify-self: start;
  }
}

@media (max-width: 600px) {
  .title-text {
    padding-left: 3vw;
  }

  .arrow-part {
    padding-right: 20vw;
  }

  .acceptance-setup {
    font-size: 0.8em;
  }
}

@media (max-width: 465px) {
  .acceptance-setup {
    font-size: 0.75em;
  }
}

@media (max-width: 450px) {
  .title-text {
    padding-left: 7.5vw;
    font-size: 0.75em;
  }
}

@media (max-width: 360px) {
  .missing-inputs-modal {
    font-size: 0.9em;
  }
}
