////////// BUTTONS /////////

button {
  all: unset;
  font-family: inherit;
}

.button-container {
  // border: 1px solid yellow;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  // width: fit-content;
}

.blue-button {
  min-width: 2.5vw;
  max-width: fit-content;
  height: fit-content;
  background-color: $light-blue3-full-opacity;
  padding: 5px;
  border: 2px solid $white-full-opacity;
  border-radius: 5px;
  box-shadow: 0px 3px 5px black;
  color: $white-full-opacity;
  font-weight: bold;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  text-align: center;
}

.red-button {
  min-width: 2.5vw;
  max-width: fit-content;
  height: fit-content;
  background-color: $white-full-opacity;
  padding: 5px;
  border: 2px solid $red-full-opacity;
  border-radius: 5px;
  box-shadow: 0px 3px 5px black;
  color: $red-full-opacity;
  font-weight: bold;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  text-align: center;
}

.green-button {
  min-width: 2.5vw;
  max-width: fit-content;
  height: fit-content;
  background-color: $green-full-opacity;
  padding: 5px 25px 5px 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px black;
  color: $white-full-opacity;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  text-align: center;
}

.light-blue1-button {
  min-width: 2.5vw;
  max-width: fit-content;
  height: fit-content;
  background-color: $light-blue1-full-opacity;
  padding: 5px;
  border: 2px solid $white-full-opacity;
  border-radius: 5px;
  box-shadow: 0px 3px 5px black;
  color: $white-full-opacity;
  font-weight: normal;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  text-align: center;
}

.orange-button {
  min-width: 2.5vw;
  max-width: fit-content;
  height: fit-content;
  background-color: $orange-full-opacity;
  padding: 5px 25px 5px 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px black;
  color: $white-full-opacity;
  cursor: pointer;
  font-family: Designosaur, sans-serif;
  text-align: center;
}

.blue-badge {
  width: fit-content;
  height: fit-content;
  background-color: $light-blue1-full-opacity;
  border: 2px solid white;
  border-radius: 5px;
  margin-left: 2vh;
  padding-bottom: 0.25vh;
  padding-left: 0.75vh;
  padding-right: 0.75vh;
  font-size: 0.85em;
  box-shadow: 0px 3px 5px black;
  text-align: center;
  font-family: Designosaur, sans-serif;
}

.red-badge {
  width: fit-content;
  height: fit-content;
  background-color: $red-full-opacity;
  border: 2px solid white;
  border-radius: 5px;
  margin-left: 2vh;
  padding-bottom: 0.25vh;
  padding-left: 0.75vh;
  padding-right: 0.75vh;
  font-size: 0.75em;
  box-shadow: 0px 3px 5px black;
  text-align: center;
  font-family: Designosaur, sans-serif;
}

///////// BUTTONS END /////////////
