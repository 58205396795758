/* Reset all default styles */
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
ul,
ol,
li,
input,
label,
form {
  min-width: none;
  max-width: none;
  min-height: none;
  max-height: none;
  margin: 0;
  padding: 0;
  border: none;
  font-family: Plus Jakarta Sans Variable, sans-serif;
  color: $white-full-opacity;
  font-style: normal;
  text-decoration: none;
  list-style: none;
}
