#cgu-modal-container {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // backdrop-filter: blur(0.5px); /* Applique un flou au contenu derrière la div */
}

#cgu-container {
  background-color: $dark-blue1-full-opacity;
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  margin-top: 5vh;
  width: 75%;
  height: 75vh;
  padding: 5vh;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
  text-align: justify;
  overflow: auto;
}

p {
  text-align: justify;
  font-style: italic;
  font-size: 0.85em;
  line-height: 4vh;
  margin-top: 3vh;
}
