#all-campaigns-container {
  // border: 1px solid red;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  margin-bottom: 5vh;
  text-align: center;
}

#campaigns-header {
  // border: 5px solid orange;
  display: grid;
  grid-template-columns: 5% 15% 40% 40%;
  background-color: $white-full-opacity;
}

.header-text-container {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.header-text {
  color: rgb(51, 34, 34);
  // text-align: left;
  font-weight: bold;
  text-align: center;
}

.campaign-details-container {
  // border: 5px solid green;
  display: grid;
  grid-template-columns: 5% 15% 40% 40%;
  border-top: 1px solid white;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 0.75em;
}

.detail-container {
  width: 100%;
}

.summary-chart {
  // border: 1px solid orange;
  width: 30%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: auto;
  margin-right: auto;
}

// Media queries
@media (max-width: 800px) {
  #campaigns-history-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .campaigns-history-lines {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 20px;
    width: 95%;
    background-color: rgba(213, 213, 255, 0.5);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  }

  .header-text {
    font-size: 0.75em;
  }
}

@media (max-width: 600px) {
  .header-text {
    font-size: 0.60em;
  }

  .campaign-details-container{
    font-size: 0.60em;
  }

  #campaign-summary {
    margin-top: 30vh;
  }

  #summary-chart {
    display: none;
  }

  #summary-text {
    display: grid;
    grid-template-columns: 1fr;
  }
}
