@font-face {
  font-family: "Nunito-VariableFont";
  src: url("../../fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Designosaur";
  src: url("../../fonts/Designosaur.ttf.woff") format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans Italic";
  src: url("../../fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans Variable";
  src: url("../../fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
}

.text-left {
  text-align: left;
}

.font-size-060 {
  font-size: 0.6em;
}

.font-size-075 {
  font-size: 0.75em;
}

.font-size-085 {
  font-size: 0.85em;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-white {
  color: $white-full-opacity;
}

.font-red {
  color: $red-full-opacity;
}

.font-green {
  color: $green-full-opacity;
}

.font-dark-blue1 {
  color: $dark-blue1-full-opacity;
}

.font-style-italic {
  font-style: italic;
}

.font-family-jakarta-sansvariable{
font-family: Plus Jakarta Sans Variable, sans-serif;;
}

.font-family-designosaur {
  font-family: Designosaur, sans-serif;
}

.font-family-nunito-variablefont {
  font-family: Nunito-VariableFont, sans-serif;
}

.text-decoration-none {
  text-decoration: none;
}

.text-center {
  text-align: center;
}
