#summary-buttons-container {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}

// #summary-infos-container {
//   // border: 5px solid purple;
//   width: 50%;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 5vh;
// }

#summary-infos-container {
  // border: 5px solid purple;
  border: 1px solid $white-full-opacity;
  border-radius: 1vh;
  background-color: $dark-blue3-full-opacity;
  display: grid;
  grid-template-columns: 1fr;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.single-info-container {
  // border: 1px solid orange;
  border-bottom: 1px solid $white-half-opacity;
  display: grid;
  grid-template-columns: 50% 50%;
  // min-height: 10vh;
  height: fit-content;
  align-items: center;
  padding: 2vh;
}

.single-info-title {
  // border: 1px solid blue;
}

.single-info-content {
  // border: 1px solid yellow;
}

.emails-list-container {
  // border: 1px solid red;
  width: 100%;
  height: auto;
  overflow-y: auto;

  // padding-top: 3vh;
}

.emails-listing {
  // font-family: Designosaur, sans-serif;
  color: $white-full-opacity;
  margin-bottom: 2vh;
}

//Media queries
// @media (max-width: 735px) {
//   #summary-infos-container {
//     font-size: 0.85em;
//   }
// }

@media (max-width: 550px) {
  #summary-infos-container {
    width: 80%;
    font-size: 0.85em;
  }

  .single-info-container {
    grid-template-columns: none;
    grid-template-rows: auto auto;
  }

  .emails-list-container {
    padding-top: 2vh;
  }
}
