/////////// ANIMATIONS ///////////

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 2s infinite;
}

.blinking-2s {
  animation: blink 2s infinite; /* Animation rapide pendant 5 secondes */
  animation-fill-mode: forwards; /* Maintenir la dernière frame après l'animation */
}

.blinking-red-text {
  animation: blink 2.5s infinite;
  color: rgb(255, 104, 104);
  font-weight: bold;
}

@keyframes translationVertical {
  0% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.translation-vertical {
  animation-name: translationVertical;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes translationHorizontal {
  0% {
    transform: translateX(30vw);
  }
  100% {
    transform: translateX(0);
  }
}

.translation-horizontal {
  animation: translationHorizontal 0.5s ease-in-out;
}

////////// Animation used for cookies banner
@keyframes cookiesBannerDisplay {
  0% {
    transform: translateY(+100px);
  }
  100% {
    transform: translateY(0);
  }
}

.cookies-banner-animation {
  animation: cookiesBannerDisplay 1.25s linear;
}

//////////// ANIMATIONS END //////////
