#cgv-modal-container {
  // border: 1px solid red;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

#cgv-container {
  background-color: $dark-blue1-full-opacity;
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  margin-top: 5vh;
  width: 75%;
  height: 75vh;
  padding: 5vh;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
  text-align: justify;
  overflow: auto;
}

#disclaimer-container {
  // border: 1px solid orange;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

#all-rules-container {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-top: 3vh;
  width: 100%;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.single-rule-container {
  text-align: justify;
  font-size: 0.85em;
  padding: 1vh;
  margin-bottom: 3vh;
  border-bottom: 1px solid white;
  width: 90%;
}

h2 {
  text-align: center;
}

p {
  text-align: justify;
  font-style: italic;
  font-size: 0.85em;
  line-height: 4vh;
  margin-top: 3vh;
}

//Media queries
@media (max-width: 1000px) {
  #all-rules-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px) {
  #all-rules-container {
    grid-template-columns: 1fr;
  }
}
