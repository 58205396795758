// /////////// Styles for COOKIES BANNER //////////////
#cookies-banner-container {
  // border: 1px solid red;
  background-color: $white-full-opacity;
  display: grid;
  grid-template-columns: 5fr 1fr;
  place-items: center;
  width: 99%;
  height: 7.5vh;
  margin-top: 92.5vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: +1;
}

#cookies-disclaimer-container {
  //   border: 1px solid red;
  color: $dark-blue1-full-opacity;
  justify-self: start;
  margin-left: 1vw;
}

#cookies-buttons-container {
  //   border: 1px solid red;
  justify-self: end;
  margin-right: 1vw;
}

/////////// Styles for COOKIES BANNER END //////////////

/////////// Styles for COOKIES POLICY //////////////
#cookies-policy-modal-container {
  background-color: $black-half-opacity;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Assurez-vous que la div occupe toute la largeur */
  height: 100%; /* Assurez-vous que la div occupe toute la hauteur */
  // backdrop-filter: blur(0.5px); /* Applique un flou au contenu derrière la div */
}

#cookies-policy-container {
  background-color: $dark-blue1-full-opacity;
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  margin-top: 15vh;
  width: 75%;
  max-height: 60vh;
  padding: 15px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 5px $white-full-opacity;
  text-align: justify;
  overflow: auto;
  // z-index: 999999;
}

/////////// Styles for COOKIES POLICY END //////////////
