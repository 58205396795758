///////// FOOTER //////////////

.footer-container {
  // border: 3px solid green;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;

  text-align: center;
  font-family: Plus Jakarta Sans Variable, sans-serif;
  color: $white-full-opacity;
  font-style: normal;
  font-size: 0.65em;
}

.logo-part {
  // border: 1px solid blue;
  display: grid;
  grid-template-columns: auto auto;
}

/////// FOOTER END ///////////

@media (max-width: 350px) {
  .footer-container {
    grid-template-columns: none;
    grid-template-rows: auto auto;
  }
}
