.system-test-disclaimer {
  // border: 5px solid orange;
  background-color: $white-full-opacity;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  padding: 2vh;
  border-radius: 1vh;
  text-align: justify;
  color: $dark-blue1-full-opacity;
}

.system-test-buttons {
  // border: 5px solid orange;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
}

////// SYSTEM TEST MODAL STYLES //////

.system-test-modal {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // padding: 10vh;
}

.system-test-form {
  background-color: $white-full-opacity;
  border-radius: 1vh;
  width: 50%;
  margin-top: 40vh;
  margin-left: auto;
  margin-right: auto;
  padding: 5vh;
}

////// SYSTEM TEST MODAL STYLES END ///////

// Media queries
@media (max-width: 700px) {
  .system-test-disclaimer {
    width: 80%;
    font-size: 0.9em;
  }

  .system-test-buttons {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    font-size: 0.9em;
  }
}
