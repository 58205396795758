/// BORDERS STYLES ////

.border-none {
  border: none;
}

.border-red {
  border: 1px solid red;
}

.border-green {
  border: 10px solid rgb(49, 231, 255);
}

.border-radius-50pc {
  border-radius: 50%;
}

.border-bottom-1px-solid-black {
  border-bottom: 1px solid rgba(0, 0, 0);
}

.border-bottom-1px-solid-grey {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.border-3px-solid-white {
  border: 3px solid white;
}

.border-3px-solid-red {
  border: 3px solid red;
}

.box-shadow {
  box-shadow: 1px 1px 0px 0px $white-full-opacity;
}

/// BORDERS STYLES END //////////

////////// MARGINS & PADDINGS STYLES /////////////
.div-center {
  // border: 1px solid red;
  margin-left: auto;
  margin-right: auto;
}

.margin-left-0-1vw {
  margin-left: 0.1vw;
}

.margin-left-1vw {
  margin-left: 1vw;
}

.margin-left-2vw {
  margin-left: 2vw;
}

.margin-left-3vw {
  margin-left: 3vw;
}

.margin-left-5vw {
  margin-left: 5vw;
}

.margin-right-0-1vw {
  margin-right: 0.1vw;
}

.margin-right-0-5vw {
  margin-right: 0.5vw;
}

.margin-right-1vw {
  margin-right: 1vw;
}

.margin-right-2vw {
  margin-right: 2vw;
}

.margin-right-3vw {
  margin-right: 3vw;
}

.margin-top-0-5vh {
  margin-top: 0.5vh;
}

.margin-top-1vh {
  margin-top: 1vh;
}
.margin-top-2vh {
  margin-top: 2vh;
}

.margin-top-5vh {
  margin-top: 5vh;
}

.margin-top-10vh {
  margin-top: 10vh;
}

.margin-bottom-0-5vh {
  margin-bottom: 0.5vh;
}

.margin-bottom-1vh {
  margin-bottom: 1vh;
}

.margin-bottom-2vh {
  margin-bottom: 2vh;
}

.margin-bottom-5vh {
  margin-bottom: 5vh;
}

.padding-left-1vw {
  padding-left: 1vw;
}

.padding-left-2vw {
  padding-left: 2vw;
}


.padding-right-0-5vw {
  padding-right: 0.5vw;
}

.padding-right-1vw {
  padding-right: 1vw;
}

.padding-right-2vw {
  padding-right: 2vw;
}

.padding-right-10vw {
  padding-right: 10vw;
}

.padding-bottom-1vh {
  padding-bottom: 1vh;
}

.padding-bottom-2vh {
  padding-bottom: 2vh;
}

.padding-top-0-5vh {
  padding-top: 0.5vh;
}

.padding-top-1vh {
  padding-top: 1vh;
}

.padding-top-1-5vh {
  padding-top: 1.5vh;
}

.padding-top-3vh {
  padding-top: 3vh;
}

///////// WIDTHS AND HEIGHTS STYLES /////

.width-100pc {
  width: 100%;
}

.width-50pc {
  width: 50%;
}

.width-30pc {
  width: 30%;
}

.width-30vw {
  width: 30vw;
}

.width-50vw {
  width: 50vw;
}

.width-fit-content {
  width: fit-content;
}

.height-50vh {
  height: 50vh;
}

///////// WIDTHS AND HEIGHTS STYLES END /////

/////// ALIGNMENTS STYLES /////////

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.align-self-start {
  align-self: start;
}

/////// ALIGNMENTS STYLES END /////////
